import {cva} from 'class-variance-authority';

import Link, {type LinkProps} from '@/components/base/elements/Link/Link';
import HeadingGroup, {
  type HeadingGroupProps,
} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {twMerge} from '@/stylesheets/twMerge';
import type {ImageProps} from '@/components/base/elements/Image/Image';
import Image from '@/components/base/elements/Image/Image';

const styles = cva('h-full flex flex-col p-6 md:p-8 rounded-2xl min-h-100', {
  variants: {
    hasHoverEffect: {
      true: 'hover:-translate-y-3 transition-transform duration-300',
      false: '',
    },
    disableShadow: {
      true: '',
      false: 'card-box-shadow',
    },
  },
});

export interface ColorBlockCardProps {
  headingGroup: HeadingGroupProps;
  link: LinkProps;
  mode?: HeadingGroupProps['mode'];
  image?: ImageProps;
  className?: string;
  hasHoverEffect?: boolean;
  disableShadow?: boolean;
  componentName?: string;
}

export default function ColorBlockCard({
  headingGroup,
  link,
  mode = 'light',
  image,
  className,
  hasHoverEffect = false,
  disableShadow = false,
  componentName = 'color-block-card',
}: ColorBlockCardProps) {
  return (
    <div
      data-component-name={componentName}
      className={twMerge(styles({hasHoverEffect, disableShadow}), className)}
    >
      {image && (
        <div>
          <Image {...image} className={twMerge('pb-4', image.className)} />
        </div>
      )}
      <div className="flex-grow">
        <HeadingGroup
          {...headingGroup}
          kickerAs="h2"
          headingAs="h1"
          size="t5"
          mode={mode}
          className="[&_h2]:text-sm [&_h2]:font-medium"
        />
      </div>
      <Link size="large" aria-label={link.text} {...link} mode={mode}>
        {link.text}
      </Link>
    </div>
  );
}
