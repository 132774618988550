import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import Typography from '@/components/base/elements/Typography/Typography';

export interface StatCardProps {
  statNumber: string;
  statText: string;
  placement?: 'standalone' | 'top' | 'bottom';
  className?: string;
}

export default function StatCard({
  statNumber,
  statText,
  placement = 'standalone',
  className,
}: StatCardProps) {
  const styles = cva('flex items-center p-6 lg:p-8 bg-lime text-black', {
    variants: {
      placement: {
        standalone: 'rounded-2xl',
        top: 'rounded-t-2xl',
        bottom: 'rounded-b-2xl',
      },
    },
  });
  const componentName = 'stat-card';

  return (
    <div
      data-component-name={componentName}
      className={twMerge(styles({placement}), className)}
    >
      <p className="text-[44px] xs:text-[45px] sm:text-[46px] md:text-[48px] lg:text-[52px] leading-none mr-2 font-ibmmono font-light ">
        {statNumber}
      </p>
      <Typography as="p" size="body-base">
        {statText}
      </Typography>
    </div>
  );
}
